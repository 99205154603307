import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import BotList from '../components/BotList';
import { searchBots } from '../services/api';
import './FindPage.css';

function FindPage() {
  const [searchParams] = useSearchParams();
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const query = searchParams.get('q');
    if (query) {
      performSearch(query);
    }
  }, [searchParams]);

  const performSearch = async (query) => {
    setIsLoading(true);
    try {
      const results = await searchBots(query);
      setSearchResults(results);
      setError(null);
    } catch (error) {
      console.error('Search failed:', error);
      setError('Failed to search bots. Please try again.');
      setSearchResults(null);
    } finally {
      setIsLoading(false);
    }
  };

  const currentQuery = searchParams.get('q');

  return (
    <div className="find-page">
      <div className="construction-notice">
        <span className="construction-icon">🚧</span>
        This page is under construction. Some features may not be available.
      </div>

      {/* Trending Bots - Always visible */}
      <div className="trending-section">
        <h2>Trending Bots</h2>
      </div>

      {/* Search Results - Only shown when there's a query */}
      {currentQuery && (
        <div className="search-results-section">
          <h2>Search Results for "{currentQuery}"</h2>
          {isLoading ? (
            <div className="loading">Searching...</div>
          ) : error ? (
            <div className="error-message">{error}</div>
          ) : searchResults && Object.values(searchResults).some(arr => arr.length > 0) ? (
            <BotList bots={searchResults} />
          ) : (
            <div className="no-results">
              <p>No bots found matching your search.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FindPage;