import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BotSearch from '../components/BotSearch';
import BotList from '../components/BotList';
import BotTemplateList from '../components/BotTemplateList';
import HuggingFaceModelList from '../components/HuggingFaceModelList';
import LegalNotice from '../components/LegalNotice';
import { fetchUserInfo, getBotTemplates, API_BASE_URL } from '../services/api';
import './HomePage.css';
import BotUploadForm from '../components/bots/BotUploadForm';


function HomePage() {
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading] = useState(false);
  const [error] = useState(null);
  const [botTemplates, setBotTemplates] = useState([]);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userTokens, setUserTokens] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
    fetchBotTemplates();
  }, []);

  const fetchUserData = async () => {
    try {
      const data = await fetchUserInfo();
      setUserName(data.username);
      setUserTokens(data.tokens);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUserName(null);
      setUserTokens(null);
    }
  };

  const handleSearchResults = async (results) => {
    console.log('Search results:', results);
    setSearchResults(results);
  };

  const fetchBotTemplates = async () => {
    try {
      const templates = await getBotTemplates();
      setBotTemplates(templates);
    } catch (error) {
      console.error('Error fetching bot templates:', error);
    }
  };

  const handleLogin = () => {
    window.location.href = `${API_BASE_URL}/login`;
  };

  const handleLogout = () => {
    window.location.href = `${API_BASE_URL}/logout`;
  };

  return (
    <div className="home-page">
      <header className="header">
        <h1>Botique.ai</h1>
        <p className="tagline">Your AI-powered bot boutique</p>
        <p className="subtitle">Discover, deploy, and interact with unique AI bots</p>
        <div className="user-info">
          {userName ? (
            <>
              <span className="token-count">
                <span className="diamond-icon">&#9830;</span>
                {userTokens}
              </span>
              <span className="username" title={userName}>{userName}</span>
              <button onClick={handleLogout} className="logout-button">Logout</button>
            </>
          ) : (
            <>
              <span className="not-logged-in">You are not logged in</span>
              <button onClick={handleLogin} className="login-button">Login</button>
            </>
          )}
        </div>
      </header>
  
      <main>

        <section className="info-section">
          <h2>About Botique.ai</h2>
          <p>
            Botique.ai is your comprehensive platform for AI-powered bots. Key services we offer:
          </p>
          <ul>
            <li>
              <strong>Discover:</strong> Search for existing bots across various platforms.
            </li>
            <li>
              <strong>Deploy:</strong> Easily deploy your custom AI models to bots with minimal effort, bringing your unique AI solutions to life.
            </li>
            <li>
              <strong>Hugging Face Integration:</strong> Deploy state-of-the-art models from Hugging Face directly to Telegram or Discord.
            </li>
          </ul>
          <p>
            Whether you're looking for existing solutions, want to bring your own AI models to the world of bots, or leverage cutting-edge models from Hugging Face, Botique.ai provides the tools and platform you need.
          </p>
          <div className="social-links">
            <a href="https://x.com/botiqueAI" target="_blank" rel="noopener noreferrer" className="twitter-link">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
              Follow us on Twitter
            </a>
          </div>
        </section>
      </main>
      
      <LegalNotice />
    </div>
  );
}

export default HomePage;
