import React from 'react';
import { Link } from 'react-router-dom';
import './BotTemplateList.css';

function BotTemplateList({ templates }) {
  return (
    <div className="template-grid">
      {templates.map((template) => (
        <Link 
          key={template.id} 
          to={`/bot-templates/${template.id}`} 
          className="template-item"
        >
          <h3>{template.name}</h3>
          <p>{template.description}</p>
        </Link>
      ))}
    </div>
  );
}

export default BotTemplateList;
