import React from 'react';
import { Link } from 'react-router-dom';
import './TutorialPage.css';

function TutorialPage() {
  const tutorials = [
    {
      title: "Getting Started with Botique.ai",
      description: "Learn the basics of using Botique.ai platform",
      link: "/tutorial/basics",
      icon: "🚀"
    },
    {
      title: "Deploy from Templates",
      description: "Learn how to create bots using our pre-built templates",
      link: "/tutorial/deploy-from-templates",
      icon: "📝"
    },
    {
      title: "Deploy Hugging Face Models",
      description: "Learn how to deploy Hugging Face models as bots",
      link: "/tutorial/deploy-huggingface",
      icon: "🤗"
    }
  ];

  return (
    <div className="tutorial-page">
      <h1>Botique.ai Tutorials</h1>
      <p className="intro">
        Welcome to Botique.ai tutorials! Choose a topic below to get started.
      </p>
      
      <div className="tutorial-grid">
        {tutorials.map((tutorial, index) => (
          <Link to={tutorial.link} key={index} className="tutorial-card">
            <span className="tutorial-icon">{tutorial.icon}</span>
            <h2>{tutorial.title}</h2>
            <p>{tutorial.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default TutorialPage;
