import React, { useState, useEffect } from 'react';
import BotTemplateList from '../components/BotTemplateList';
import HuggingFaceModelList from '../components/HuggingFaceModelList';
import { getBotTemplates } from '../services/api';
import './DeployPage.css';

function DeployPage() {
  const [botTemplates, setBotTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBotTemplates();
  }, []);

  const fetchBotTemplates = async () => {
    try {
      const templates = await getBotTemplates();
      setBotTemplates(templates);
      setError(null);
    } catch (error) {
      console.error('Error fetching bot templates:', error);
      setError('Failed to load templates');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="deploy-page">
      <div className="deploy-header">
        <h1>Deploy Your Bot</h1>
      </div>
      
      <div className="deploy-sections">
        <section className="templates-section">
          <h2>From Bot Templates</h2>
          <p>Choose from our pre-configured templates with built-in functionality for customer service, FAQ answering, and social media management. Deploy quickly with minimal setup.</p>
          <p>Need help? Check out our <a href="/tutorial/deploy-from-templates" className="tutorial-link">step-by-step tutorial</a>.</p>
          {isLoading ? (
            <div className="loading">Loading templates...</div>
          ) : error ? (
            <div className="error-message">{error}</div>
          ) : (
            <div className="template-list">
              <BotTemplateList templates={botTemplates} />
            </div>
          )}
        </section>

        <section className="huggingface-section">
          <h2>From 🤗HuggingFace</h2>
          <p>Deploy AI models from Hugging Face's model hub for text generation, translation, and more. Just select a model and configure it.</p>
          <p>Enter the model name from the URL (e.g. "meta-llama/Llama-3.2-1B-Instruct" from <a href="https://huggingface.co/meta-llama/Llama-3.2-1B-Instruct" target="_blank" rel="noopener noreferrer">huggingface.co/meta-llama/Llama-3.2-1B-Instruct</a>).</p>
          <p>Need help? Check our <a href="/tutorial/deploy-huggingface" className="tutorial-link">tutorial</a>.</p>
          <div className="model-list">
            <HuggingFaceModelList />
          </div>
        </section>

      </div>
    </div>
  );
}

export default DeployPage;