import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSearch, FaRocket, FaUpload, FaRobot } from 'react-icons/fa';
import './Navigation.css';

function Navigation({ userName }) {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/find?q=${encodeURIComponent(searchQuery.trim())}`);
      setSearchQuery(''); // Clear search after navigation
    }
  };
  return (
    <nav className="navigation">
      <div className="nav-content">
        <div className="nav-left">
          <Link to="/" className="nav-logo">
            Botique.ai
          </Link>
          <Link to="/find" className="nav-link">
            🔥 Trending
          </Link>
          <Link to="/deploy" className="nav-link">
            🚀 Deploy
          </Link>
          <Link to="/upload" className="nav-link">
            📤 Upload
          </Link>
          <form onSubmit={handleSearch} className="nav-search">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search bots..."
              className="nav-search-input"
            />
            <button type="submit" className="nav-search-button">
              <FaSearch />
            </button>
          </form>
        </div>
        <div className="nav-right">
          <Link to="/tutorial" className="nav-link">
              🎓 Tutorial
          </Link>
          <Link to="/my-bots" className="nav-link">
             🤖 My Bots
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
