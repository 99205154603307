import React from 'react';
import { Link } from 'react-router-dom';
import './BasicsTutorial.css';

function BasicsTutorial() {
  return (
    <div className="basics-tutorial">
      <header>
        <h1>Getting Started with Botique.ai</h1>
        <p>Learn the basics of using Botique.ai to find, deploy, and manage your bots.</p>
      </header>

      <main>
        <section>
          <h2>1. Finding Bots</h2>
          <p>Botique.ai makes it easy to discover AI-powered bots:</p>
          <ul>
            <li>Use the search bar to find bots by name or description</li>
            <li>Click on any bot to view its details and capabilities</li>
          </ul>
        </section>

        <section>
          <h2>2. Deploying Your First Bot</h2>
          <p>There are two ways to deploy a bot on Botique.ai:</p>
          <div className="deployment-options">
            <div className="option">
              <h3>From Templates</h3>
              <p>Use our pre-built templates for common use cases.</p>
              <Link to="/tutorial/deploy-from-templates" className="learn-more">Learn More →</Link>
            </div>
            <div className="option">
              <h3>From Hugging Face</h3>
              <p>Deploy any Hugging Face model as a bot.</p>
              <Link to="/tutorial/deploy-huggingface" className="learn-more">Learn More →</Link>
            </div>
          </div>
        </section>

        <section>
          <h2>3. Uploading Your Own Bot</h2>
          <p>Have a custom bot you'd like to share with the community? Here's how to upload it:</p>
          <ol>
            <li>Go to the "Upload Your Own Bot" section on the homepage</li>
            <li>Click "Show Upload Form" to reveal the upload interface</li>
            <li>Fill in your bot's details:
              <ul>
                <li>Name and description</li>
                <li>Platform (Telegram or Discord)</li>
                <li>Bot token or invite link</li>
                <li>Tags to help others find your bot</li>
              </ul>
            </li>
            <li>Click "Upload Bot" to share it with the community</li>
          </ol>
          <p>Once uploaded, your bot will be discoverable by other users through the search function.</p>
        </section>

        <section>
          <h2>4. Managing Your Bots</h2>
          <p>Once you've deployed a bot, you can:</p>
          <ul>
            <li>Monitor its status in the "My Bots" page</li>
            <li>Stop the bot at any time</li>
            <li>View usage statistics and logs</li>
          </ul>
          <img src="/images/manage-bots.png" alt="Managing Bots" className="tutorial-image" />
        </section>
      </main>

      <footer>
        <Link to="/tutorial" className="back-to-tutorials">← Back to Tutorials</Link>
      </footer>
    </div>
  );
}

export default BasicsTutorial;
