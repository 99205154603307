import React, { useState, useEffect } from 'react';
import { getUserBots, stopBot } from '../../services/api';
import './BotManagement.css';
import BotLogger from '../BotLogger';

function BotManagement() {
  const [userBots, setUserBots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchUserBots();
    // Poll for updates every 30 seconds
    const interval = setInterval(fetchUserBots, 30000);
    return () => clearInterval(interval);
  }, [searchQuery]);

  const fetchUserBots = async () => {
    try {
      setIsLoading(true);
      const bots = await getUserBots(searchQuery);
      setUserBots(bots);
    } catch (error) {
      console.error('Error fetching user bots:', error);
      setError('Failed to fetch user bots. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStopBot = async (botToken) => {
    try {
      await stopBot(botToken);
      // Refresh the bot list
      fetchUserBots();
    } catch (error) {
      console.error('Error stopping bot:', error);
      setError('Failed to stop bot. Please try again.');
    }
  };

  if (isLoading) return <p>Loading your bots...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div className="bot-management">
      <h2>Your Active Bots</h2>
      <input
        type="text"
        placeholder="Search your bots"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {userBots.length === 0 ? (
        <p>No active bots found.</p>
      ) : (
        <ul className="bot-list">
          {userBots.map((bot) => (
            <li key={bot.id} className="bot-item">
              <div className="bot-info">
                <h3>{bot.model_name}</h3>
                <p>{bot.description}</p>
                <p>Deployed From: {bot.from_}</p>
                {bot.platform === 'telegram' && bot.bot_name && (
                  <p>Bot Name: @{bot.bot_name}</p>
                )}
                <p>Bot token: {bot.token}</p>
                <p>Platform: {bot.platform}</p>
                <p>Status: {bot.stopped ? 'Stopped' : 'Running'}</p>
                <button onClick={() => handleStopBot(bot.token)}>
                  {bot.stopped ? 'Start Bot' : 'Stop Bot'}
                </button>
                <BotLogger 
                  botToken={bot.token} 
                  isActive={!bot.stopped} 
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default BotManagement;
