export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://localhost:5001/api';

export async function searchBots(query) {
  const url = `${API_BASE_URL}/bots?query=${encodeURIComponent(query)}`;
  console.log('Requesting URL:', url);
  try {
    const response = await fetch(url, {
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Search results:', data);
    return data;
  } catch (error) {
    console.error('Error in searchBots:', error);
    throw error;
  }
}

export async function submitBotRequest(description) {
  const response = await fetch(`${API_BASE_URL}/bot-requests`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ description }),
  });
  if (!response.ok) {
    throw new Error('Failed to submit bot request');
  }
  return response.json();
}

export async function getBotRequests() {
  const response = await fetch(`${API_BASE_URL}/bot-requests`);
  if (!response.ok) {
    throw new Error('Failed to fetch bot requests');
  }
  return response.json();
}

export async function addComment(requestId, userName, content, isDeveloper = false) {
  const response = await fetch(`${API_BASE_URL}/bot-requests/${requestId}/comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user_name: userName, content, is_developer: isDeveloper }),
  });
  if (!response.ok) {
    throw new Error('Failed to add comment');
  }
  return response.json();
}

export async function getBotTemplates() {
  try {
    console.log('Fetching bot templates from API...');
    const response = await fetch(`${API_BASE_URL}/bot-templates`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Bot templates data received:', data);
    return data;
  } catch (error) {
    console.error('Error in getBotTemplates:', error);
    throw error;
  }
}

export async function getBotTemplate(id) {
  const response = await fetch(`${API_BASE_URL}/bot-templates/${id}`);
  if (!response.ok) {
    throw new Error('Failed to fetch template');
  }
  return response.json();
}

export async function createCustomBot(formData) {
  const response = await fetch(`${API_BASE_URL}/custom-bots`, {
    method: 'POST',
    body: formData,
  });
  if (!response.ok) {
    throw new Error('Failed to create custom bot');
  }
  return response.json();
}

export async function getCustomBots() {
  const response = await fetch(`${API_BASE_URL}/custom-bots`);
  if (!response.ok) {
    throw new Error('Failed to fetch custom bots');
  }
  return response.json();
}

export async function deployBot(id, config) {
  const response = await fetch(`${API_BASE_URL}/deploy`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ templateId: id, config }),
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to deploy bot');
  }
  return response.json();
}

export async function stopBot(token) {
  const response = await fetch(`${API_BASE_URL}/stop-bot`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to stop bot');
  }
  return response.json();
}

export async function getBotDetails(botIdentifier) {
  const url = `${API_BASE_URL}/bots?query=${encodeURIComponent(botIdentifier)}`;
  
  try {
    const response = await fetch(url, {
      credentials: 'include',
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    console.log('Search results:', data);

    // For Discord bots, return the first bot from discord results
    if (window.location.pathname.includes('/external_bots/discord/')) {
      const discordBot = data.discord?.[0];
      if (discordBot) {
        return {
          name: discordBot.name,
          url: discordBot.url,
          description: discordBot.description
        };
      }
    }

    // Existing Telegram bot logic
    if (window.location.pathname.includes('/external_bots/telegram/')) {
      const bot = data.telegram?.find(b => 
        b.username?.toLowerCase().includes(botIdentifier.toLowerCase()) ||
        b.name?.toLowerCase().includes(botIdentifier.toLowerCase())
      );
      
      if (bot) {
        return bot;
      }
    }
    
    throw new Error(`Bot ${botIdentifier} not found`);
  } catch (error) {
    console.error('Error in getBotDetails:', error);
    throw error;
  }
}

export async function deployHuggingfaceBot(model_name, token, platform, model_task = null) {
  const response = await fetch(`${API_BASE_URL}/deploy-huggingface-bot`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ model_name, token, platform, model_task }),
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to deploy Huggingface bot');
  }
  return response.json();
}

export async function uploadUserBot(userId, name, description, platform, platformBotName) {
  try {
    const response = await fetch(`${API_BASE_URL}/user-bots`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, name, description, platform, platform_bot_name: platformBotName }),
      credentials: 'include',
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }
    
    return response.json();
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
}

export async function getUserBots() {
  const response = await fetch(`${API_BASE_URL}/user-bots`, {
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to fetch user bots');
  }
  const data = await response.json();
  return data.bots;
}

export async function fetchUserInfo() {
  try {
    const response = await fetch(`${API_BASE_URL}/user-info`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user info');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching user info:', error);
    return { email: null, isAuthenticated: false };
  }
}
