import React, { useState, useEffect, useRef } from 'react';
import { API_BASE_URL } from '../services/api';
import './BotLogger.css';

function BotLogger({ botToken, isActive }) {
  const [logs, setLogs] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const logContainerRef = useRef(null);

  const fetchLogs = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_BASE_URL}/bot/logs/${botToken}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch logs');
      }
      const data = await response.json();
      setLogs(data.logs);
      setError(null);
    } catch (err) {
      setError('Failed to load logs');
      console.error('Error fetching logs:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isActive && botToken && showLogs) {
      fetchLogs();
    }
  }, [botToken, isActive, showLogs]);

  const toggleLogs = () => {
    setShowLogs(!showLogs);
    if (showLogs) {
      setLogs([]);
    }
  };

  const downloadLogs = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/bot/logs/${botToken}/download`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        throw new Error('Failed to download logs');
      }
      
      const data = await response.json();
      
      // Create blob and download
      const blob = new Blob([JSON.stringify(data.logs, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `bot_logs_${botToken}_${new Date().toISOString().split('T')[0]}.json`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      setError('Failed to download logs');
      console.error('Error downloading logs:', err);
    }
  };

  return (
    <div className="bot-logger">
      <div className="logger-controls">
        <button 
          onClick={toggleLogs}
          className={`view-logs-button ${showLogs ? 'active' : ''}`}
        >
          {showLogs ? 'Hide Logs' : 'View Logs'}
        </button>
        {showLogs && (
          <>
            <button 
              onClick={fetchLogs}
              className="refresh-logs-button"
              disabled={isLoading}
            >
              {isLoading ? 'Refreshing...' : 'Refresh Logs'}
            </button>
            <button 
              onClick={downloadLogs}
              className="download-logs-button"
            >
              Download Full Logs
            </button>
          </>
        )}
      </div>

      {showLogs && (
        <>
          <div className="logger-header">
            <h3>Bot Logs</h3>
            {isLoading && <span className="loading">Loading...</span>}
            {error && <span className="error">{error}</span>}
          </div>
          <div className="log-container" ref={logContainerRef}>
            {logs.length === 0 ? (
              <div className="no-logs">No logs available</div>
            ) : (
              logs.map((log, index) => (
                <div key={index} className={`log-entry ${log.level}`}>
                  <span className="timestamp">{log.timestamp}</span>
                  <span className="level">{log.level}</span>
                  <span className="message">{log.message}</span>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default BotLogger;
