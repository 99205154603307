import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './HuggingFaceModelList.css';

function HuggingFaceModelList() {
  const [modelName, setModelName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (modelName.trim()) {
      window.location.href = `/deploy-huggingface/${encodeURIComponent(modelName)}`;
    }
  };

  return (
    <div className="huggingface-model-list">
      <form onSubmit={handleSubmit} className="huggingface-form">
        <input
          type="text"
          value={modelName}
          onChange={(e) => setModelName(e.target.value)}
          placeholder="Enter Hugging Face model name"
          required
        />
        <button type="submit">Deploy Model</button>
      </form>
      <div className="popular-models">
        <h3>Popular Models:</h3>
        <div className="model-section">
          <h4>Chat Completion</h4>
          <ul>
            <li>
              <Link to="/deploy-huggingface/meta-llama%2FLlama-3.1-8B-Instruct">
                meta-llama/Llama-3.1-8B-Instruct
              </Link>
            </li>
            {/* Add more chat completion models here */}
          </ul>
        </div>
        <div className="model-section">
          <h4>Text-to-Image</h4>
          <ul>
            <li>
              <Link to="/deploy-huggingface/stabilityai%2Fstable-diffusion-xl-base-1.0">
                stabilityai/stable-diffusion-xl-base-1.0
              </Link>
            </li>
            {/* Add more text-to-image models here */}
          </ul>
        </div>
        <div className="model-section">
          <h4>Automatic Speech Recognition</h4>
          <ul>
            <li>
              <Link to="/deploy-huggingface/openai%2Fwhisper-large-v3-turbo">
              openai/whisper-large-v3-turbo
              </Link>
            </li>
            {/* Add more text-to-video models here */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HuggingFaceModelList;
